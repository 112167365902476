import { httpopen } from './configOpen'
import { http } from './config'

import authHeader from './auth-header';

export default {

    verificarcodigo: (codigo) => {
        return httpopen.get('verificarcodigo/'+ codigo)
    },

    recuperarsenha: (recuperar) => {
        return httpopen.post('recuperarsenha', recuperar)
    },

    cadastropromotor: (contrato) => {
        return httpopen.post('cadastropromotor', contrato)
    },


    saldo: () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('saldo/'+ user.email, {headers: authHeader() })
    },

    total_eventos: (evento) => {
        return http.get('total_eventos/'+ evento, {headers: authHeader() })
    },

    eventos_promotor: () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('eventos_promotor/'+ user.email, {headers: authHeader() })
    },


    eventos_premiacao: () => {
        return http.get('eventos_premiacao', {headers: authHeader() })
    },

    eventos_saldo: () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('meu_saldo/'+ user.email, {headers: authHeader() })
    },


    premiacao_promotor: () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('premiacao_promotor/'+ user.email, {headers: authHeader() })
    },

    lista_envio_sms_venda: (token) => {
        return http.get('lista_envio_sms_venda/'+token, {headers: authHeader() })
    },

    envio_sms_venda: (smsVenda) => {
        return http.post('envio_sms_venda', smsVenda, {headers: authHeader()})
    },

    lista_envio_sms_atualizacao: (token) => {
        return http.get('lista_envio_sms_atualizacao/'+token, {headers: authHeader() })
    },

    ranking_saldo: () => {
        return http.get('ranking_saldo', {headers: authHeader() })
    },

    envio_sms_atualizacao: (smsAVenda) => {
        return http.post('envio_sms_atualizacao', smsAVenda, {headers: authHeader()})
    },




    totais_dash: () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('totais_dash/'+ user.email, {headers: authHeader() })
    },
    
    totais_dash_loja: () => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        return http.get('totais_dash_loja/'+ user.email, {headers: authHeader() })
    },
    
    eventos_indicacao: () => {
        return http.get('eventos_indicacao', { headers: authHeader() })
    },

   
    
    indicacao_flores: (indicacao) => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        indicacao.valor = user.email;
        return http.post('indicacao_flores', indicacao, {headers: authHeader()})
    },

    
    premiar: (controle_premiacao) => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        controle_premiacao.email_promotor =  user.email;
        return http.post('solicitar_premiacao_promotor', controle_premiacao, { headers: authHeader() })
    },

  

    incluirPontoToken: (ev) => {
        return httpopen.post('incluirPontoToken', ev )
    },


}
