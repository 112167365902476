import axios from 'axios'
//const url = 'http://localhost:8091/apiopen/'
const url = 'https://maestrix.grupoparque.com.br:8443/apiprevida/apiopen/'


export const httpopen = axios.create({
     baseURL: url, 
})

export default {
     url,
}