<template>

  <div style="height:100vh" >
      <div data-app>
      <v-dialog  v-model="alert_msg"  width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Mensagem
          </v-card-title>

          <v-card-text>
              {{msg_dialog}}
          </v-card-text>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="alert_msg = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <v-dialog
      v-model="dialog_recuperar"
      persistent
      max-width="450px" >
     
      <v-card>
        <v-card-title>
          <span class="text-h5">Recuperar senha</span> <br/>
        </v-card-title><br/><br/>

                  Enviaremos um SMS com a nova senha de acesso, por favor confirme os dados conforme cadastro.

          <v-card-text>
          <br/>
            <v-container>
              <v-row>
              
                <v-col cols="12" md="12">
                    <v-text-field 
                      label="Informe Telefone"  v-mask="'(##) #####-####'"  Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
                      filled v-model="recuperar.telefone"
                      prepend-inner-icon="mdi-cellphone-basic">
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field 
                      label="Informe E-mail"    Required :rules="[v => !!v || 'Campo Obrigatório']" style="margin-left:4px;"
                      filled v-model="recuperar.email" 
                      prepend-inner-icon="mdi-email">
                    </v-text-field>
                  </v-col>
              </v-row>
            </v-container>
          </v-card-text>          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1"  text @click="fechar"  >
              Fechar
            </v-btn>
            <v-btn  style="background-color:#4caf50;" color=success class="white--text" :loading="this.loading"
                  @click="recupearSenha">
                  <b>Confirmar</b>
                  <v-icon right dark>mdi-check</v-icon>
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
      </div>


    <v-container class="text-center">
         <img src="../../assets/logo_tamojunto.png" class="img_central" width="50px"  alt="" />
          <h2>Acesso</h2>
          <hr>
          <br/>
          <br/>
            <v-row>   
              <v-col cols="12">
                <v-text-field 
                    label="E-mail" Required :rules="[v => !!v || 'Campo Obrigatório']"
                    filled  v-model="user.username" x-large
                    prepend-inner-icon="mdi-email"
                  ></v-text-field>
              </v-col>

              <v-col cols="12">
                  <v-text-field 
                  label="Senha" type='password' Required :rules="[v => !!v || 'Campo Obrigatório']"
                  filled v-model="user.password"
                  prepend-inner-icon="mdi-key"
                ></v-text-field>      
              </v-col>
          
            </v-row>
        </v-container>

      <div>
            
        
          <v-btn  style="width:250px;background-color:#1c5587;"
           class="white--text"
          @click="handleLogin" x-large rounded 
          :loading="loading">
          <b>Entrar</b>
          <v-icon right dark>mdi-lock-open</v-icon>
          </v-btn>

       <br/>
      <div style="margin-top:10px">
        
          <v-btn color="blue darken-1" rounded style="width:250px;"
            class="white--text" to="cadastro">
          
            <b>Quero me Cadastrar</b>
            <v-icon right dark>mdi-account-plus</v-icon>
          </v-btn>

          <v-btn  style="width:250px;background-color:#rgb(0 0 0 / 87%)"  class="white--text"
                @click="dialog_recuperar = true" rounded  >
                <b>Recuperar Senha</b>
                <v-icon right dark>mdi-key-star</v-icon>
          </v-btn>
      </div>


       
      </div>
       
     <section class="s-hero">

    </section>

  
        
  <footer>
      <div class="container">
        <div class="social">
          <div class="text">
                    <img src="../../assets/logo_previda.png" class="img_central"  width="120px" alt="" />

            <p>Rua José Alencar, nº 121, Maceió</p>
            <p>{{ new Date().getFullYear() }} -<strong> Previda</strong></p>
          </div>
        </div>
    
      </div>
    </footer>

  </div>  
</template>

<script>
import User from '../../models/user'
  import Promotor from '../../services/promotor'



  export default {
    name: 'Login',
    data () {
      return {
         components: {
          //DashboardCoreView: () => import('../dashboard/components/core/View'),
          },
        
        user: new User('', ''),
        alert_msg:false,
        msg_dialog:'',
        recuperar:{
          telefone: '',
          email: '',
        },
        loading: false,
        message: '',
        chip2: false,
        dialog_recuperar: false,
        expandOnHover: false,
       expandOnHover: false,

    }
    },

    computed: {
      loggedIn () {
        return this.$store.state.auth.status.loggedIn
      },
    },
    created () {
      this.$store.dispatch('auth/logout');
      //this.$router.push('/pages/login');
    
      //if (this.loggedIn) {
       // this.$router.push('/dashboard')
     // }

    },
    methods: {
      handleLogin () {        

        this.loading = true
        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
            (response) => {
              const tipo = response.externo;
                    if(tipo){
                      this.showload = true;
                      this.$router.push('/dashboard')
                      this.loading = false
                    }else{ 
                    
                    this.loading = false
                    this.msg_dialog = 'Usuário ou senha não confere',
                    this.alert_msg = true
                    //alert( this.msg_dialog )
                    }

            },
            error => {
              this.alert_msg = true,
              this.loading = false,
              this.msg_dialog = 'Usuário ou senha não confere'
              //alert( this.msg_dialog )

            },
          )
        }
      },

      fechar(){
        this.dialog_recuperar = false;
      },

      recupearSenha(){
            this.loading = true;

            Promotor.recuperarsenha(this.recuperar).then(response => {
               
               this.msg_dialog = response.data;
                this.alert_msg = true;
                this.dialog_recuperar = false;
                this.recuperar.telefone = "";
                this.recuperar.email = "";

              }).catch(e => {
              if(e.status === 401){
                this.loading = false;   
              }
             }).finally(() => {
               this.loading = false
               this.fechar;
              })
      },




    },
  }
</script>


<style scoped> 

*{
 
  background-repeat: no-repeat;
  padding:0;
  list-style:none;
  text-decoration:none;
  text-align:center;
  outline:none;
  border:none;
  box-sizing:border-box;
  font-family:"roboto";

  }


  logo{
       flex-direction:row;
    align-items:center;
  }

  hr{
  border: 2px solid #565d68;
  border-radius: 5px;
  width:50%;
  margin-left:25%;

  }

  .tudo{
    height: 100%;
  }


  @media screen and (max-width: 1000px) {
    .logomarca{
      max-width:200px
    }
      
  }  
    .logomarca{
      width:240px;
    }

  img{
    line-height: normal;
    max-width:200px;    
    }

    .img_central{

        justify-content:center;

    }
  html{
    font-size:22.5%;
    
    }


 
  @media (max-width: 1300px){
    
    html{
      font-size:18%
      }
      .container{
        width:100%;
        max-width:300px;
        padding:1.5rem;
        color:#464646; 
        margin:0 auto

    }
  }
  @media (max-width: 90px){
     
    html{
      font-size:1.5%
      }
  }@media (max-width: 20px){
    html{
      font-size:28%
      }
  }
  html,body{
    overflow-x:hidden
    }
  button{
    background-color:transparent;
    cursor:pointer
  }
  .container{
    width:100%;
    padding:1.5rem;
    color:#464646; 
    max-width:500px;
   margin:0 auto
  }
  header{
    padding:5rem 0;
    text-align:center;
    font-size:1rem;
    font-family:"roboto";
    justify-content:center;
  }
  header .container{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
  }
  header .container .text h1{
    text-transform:uppercase;
    font-size:2.8rem;
    margin-bottom:0.1rem;
    text-align:center;
        letter-spacing:0.2rem;
    font-family:"roboto";
    color:#565d68;

  }
  header .container .text h3{
    font-size:1.3rem;
    color: white;
    text-align:center;
    background:#9fc1d7;
    letter-spacing:0.1rem;
    font-family:"roboto";
    color:#565d68;

  }
  @media (max-width:485px){ .figlado{ visibility: hidden; } }
  @media (max-width: 100px){
    header .container{
      flex-direction:column;}
  }
  @media (max-width: 480px){

   
    header .container .text h1{
      font-size:1.2rem
    }
    header .container .text h3{
      font-size:1rem;
      }
    }

    footer{
      position:relative;

      padding-bottom:0
    }
    footer .container{
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      padding-bottom:0;
      height:12rem
    }
    footer .container .img-left{
      position:absolute;
      bottom:0;
      left:0
    }
    footer .container .img-right{
      position:absolute;
      bottom:0;
      right:0
    }
    footer .container .social{
      width:50%
    }
    footer .container .social .icones{
      display:flex;
      flex-direction:row;
      max-width:180px;
      align-items:center;
      background-color:blue;
      justify-content:center;
      width:100%
    }
    footer .container .social .icones .icone{
      background:#0a5587;
      background-color:blue;
            justify-content:center;
      max-width:180px;
      width:74px;
      height:74px;
      padding:1.2rem;
      border-radius:25%
      }
      footer .container .social .text{
        margin-top:0.1rem
      }
      footer .container .social .text p{
        text-align:center;
        font-size:1rem;
        font-family:'roboto';
        color:#464646;
        letter-spacing:0.2rem
      }
      footer .container .social .text p:last-child{
        margin-top:1rem
      }
      @media (max-width: 1160px){
          
        footer .container{
          height:55rem
        }
        footer .container .img-left,footer .container .img-right{
          max-width:25%
          
          }
        footer .container .social .text p{
          font-size:1rem
        }
      }
      
      @media (max-width: 480px){
        footer .container{
          height:12rem
        }
        footer .container .social{
          width:100%
        }
      }
      .s-hero .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        padding:2rem 0;
      }
      
      .s-hero .container iframe{
        width:100%;max-width:98%;height:45rem
      }

      .s-contact{
        padding:1rem 0
      }
      .s-contact .container{
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-around
      }
      .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        margin-bottom:0.1rem;
        padding-bottom:5rem
      }.s-contact .container .contacts-left .contact:last-child,.s-contact .container .contacts-right .contact:last-child{
        margin-bottom:0
        }.s-contact .container .contacts-left .contact img,.s-contact .container .contacts-right .contact img{
          width:96px;height:96px;margin-left:0.1rem;font-family:'roboto';padding:0.6rem;border-radius:25%;
        }
        .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
        font-size:1.2rem;font-family:'roboto';color:#464646;line-height: normal;text-transform:uppercase}
        .s-contact .container .contacts-left .contact p,.s-contact .container .contacts-right .contact p{
          font-size:1rem;padding-left:0.1rem;color:#464646;font-family:roboto;margin-top:0.2rem
        }
        .s-contact .container .contacts-left .contact span,.s-contact .container .contacts-right .contact span{
          margin-left:0.1rem;font-size:1.2rem;font-family:'roboto';color:#464646;padding:0.1rem;margin-top:0.1rem
          }
        @media (max-width: 1000px){
            
          .s-contact{padding:0}.s-contact .container{
            align-items:center;
            flex-direction:column;
            font-family:'roboto';
            color:#464646;
            align-items:center;
            }
          .s-contact .container .contacts-left .contact,.s-contact .container .contacts-right .contact{
            align-items:center;margin:0
          }
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            text-align:left
            }
          }
        @media (max-width: 80px){
          .s-contact .container .contacts-left .contact h3,.s-contact .container .contacts-right .contact h3{
            font-size:2rem;
            font-family:'roboto';
            color:#464646;
            }
          }



 



</style>

